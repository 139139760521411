<template>
  <div class="article" v-if="data">
    <h1>{{data.name}}</h1>
    <p v-html="data.content"></p>
  </div>
</template>
<script>
import axios from 'axios';
export default {
  name: "Article",
  data() {
    return {
      data: null
    }
  },
  mounted(){
    this.getData();
  },
  methods: {
    getData(){
      axios.get('/api/notice/getDetail?id=' + this.getQueryString("id")).then(response => {
        let res = response.data;
        this.data = res.data;
      });
    }
  }
};

</script>
<style scoped lang="scss">
.article{
  padding: 20px;
  h1{
    line-height: 1.5;
    padding-bottom: 20px;
    font-size: 16px;
  }
}
</style>
